<template>
  <div>
    <topmenu :comname="'information'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>参展手册</span>
      </div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <embed :src="list[0].vAppend" type="application/pdf" width="100%" height="800px" />
        <!-- <a :href="list[0].vAppend"><img :src="list[0].vAppend" alt=""></a> -->
        <div v-if="isshowlist" class="listinfor" v-loading="loading">
          <!-- <ul>
            <li v-for="item in list" :key="item.id">
              <div class="contm">
                <span class="tlew" :title="item.vName">{{item.vName.length>40?comJs.limitNum(item.vName,40):item.vName}}</span>
                <div class="time">{{item.dCreateTime | formatDate}}</div>
              </div>
              <a :href="item.vAppend" class="gtna">下载</a>
            </li>
          </ul> -->
          <!-- <el-pagination

            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-count="totalPage"
            :current-page="currentPage"
            :page-size="pagesize"
            :hide-on-single-page="true"
            class="t_page"
          ></el-pagination> -->
                          

        </div>
        
        <nocont v-else></nocont>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
export default {
  name: "information",
  data() {
    return {
      list: [],
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize: 6, //每页显示条数
      totalPage: 0 //总页数
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont
  },
  created() {
    this.getinformation();
  },
  watch: {
    //监听翻页
    currentPage: function(newVal, oldVal) {
      this.loading = true;
      this.getinformation();
    }
  },
   filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    }
  },
  methods: {
    handleSizeChange: function(size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    getinformation() {
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true,
        iType: 1
      };
      this.$axios
        .post("/api/app/exhibitionMaterials/getExhibitionMaterialsPageList", data)
        .then(res => {
          this.loading = false;
          this.totalPage = res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata);
            this.list = infordata;
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
        });
    }
  }
};
</script>
<style scoped>
.listinfor {
  padding: 40px 0;
  overflow: hidden;
}
.listinfor ul {
  display: block;
  overflow: hidden;
   padding: 0 25px;
}
.listinfor li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:#fafafa;
    padding: 15px 10px;
    margin-bottom: 15px;
    overflow: hidden;
}
.contm{
  text-align: left;
  padding: 0 5px 0 15px;
  float: left;
  width: 768px;
}
.tlew{
  font-size: 16px;
  line-height: 22px;
  color: #333;
}
.time{
  font-size: 15px;
  line-height: 30px;
  color: #999;
}
.gtna{
	width: 74px;
	height: 30px;
	background-color: #193155;
    border-radius: 2px;
    color: #fff;
    line-height: 30px;
    display: block;
}
</style>